









































































































































































import { Component, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import TestInfo from "@/ab-tests/components/TestInfo.vue";
import AbTestCard from "@/ab-tests/components/AbTestCard.vue";
import AbTestConfigurationModel, {
  AbTestResultModel,
} from "@/ab-tests/models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: { AbTestCard, TestInfo },
})
export default class AbTestConfigurationView extends mixins(
  AppSectionAccessMixin
) {
  @Prop() value!: Array<ConfigsStatus>;

  search = "";
  abTestStatuses = Object.values(ConfigsStatus).map((status) => status);
  isShowOnlyMy = false;
  isLoadingAbTestResult = false;

  get checkedAbTestStatuses(): Array<ConfigsStatus> {
    return this.value;
  }

  set checkedAbTestStatuses(newValue: Array<ConfigsStatus>) {
    this.$emit("input", newValue);
  }

  get sortedConfigs(): Record<ConfigsStatus, Array<AbTestConfigurationModel>> {
    return Object.entries(
      this.$store.state.abTestConfig.abTestConfigs as Record<
        ConfigsStatus,
        Array<AbTestConfigurationModel>
      >
    ).reduce(
      (
        res: Record<ConfigsStatus, Array<AbTestConfigurationModel>>,
        [status, configs]: [string, Array<AbTestConfigurationModel>]
      ) => {
        const filteredConfigs = configs.filter(
          (config: AbTestConfigurationModel) =>
            config.name.toLowerCase().includes(this.search.toLowerCase()) &&
            (!this.isShowOnlyMy ||
              config.creatorId === this.currentUser.username)
        );

        if (!filteredConfigs.length) {
          return res;
        }

        return {
          ...res,
          [status]: filteredConfigs,
        };
      },
      {} as Record<ConfigsStatus, Array<AbTestConfigurationModel>>
    );
  }

  get appId(): string {
    return this.$route.params.id;
  }

  get isLoading(): boolean {
    return (
      this.$store.state.abTestConfig.loadingConfig || this.isLoadingAbTestResult
    );
  }

  get configStatusTotals(): Record<ConfigsStatus, number> {
    return this.$store.state.abTestConfig.configStatusTotals;
  }

  get existsDefaultConfig(): boolean {
    return this.$store.getters.existsDefaultConfig;
  }

  get stylesForVSheet(): string {
    return `
    border-bottom: thin solid ${
      this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.12)"
        : "rgba(0, 0, 0, 0.12)"
    } !important
    `;
  }

  @Watch("checkedAbTestStatuses")
  async watchCheckedAbTestStatuses() {
    await this.$store.dispatch(
      "loadAbTestConfigsByStatuses",
      this.checkedAbTestStatuses
    );
    await this.getResultTypeForStatisticsRecalculatedConfigs();
  }

  @Watch("appId", { immediate: true })
  async watchAppId() {
    await this.$store.dispatch(
      "loadAbTestConfigsByStatuses",
      this.checkedAbTestStatuses
    );
    await this.getResultTypeForStatisticsRecalculatedConfigs();
    await this.$store.dispatch("loadAbTestConfigStatusTotals");
  }

  async getResultTypeForStatisticsRecalculatedConfigs() {
    if (this.sortedConfigs[ConfigsStatus.STATISTICS_RECALCULATED]?.length) {
      this.isLoadingAbTestResult = true;

      await this.$store
        .dispatch(
          "getAbTestResult",
          this.sortedConfigs[ConfigsStatus.STATISTICS_RECALCULATED].map(
            (item) => item.id
          )
        )
        .then((payload: Array<AbTestResultModel>) => {
          this.$store.commit(
            "setResultTypeForAbTestById",
            AbTestResultModel.ofArray(payload)
          );
        });

      this.isLoadingAbTestResult = false;
    }
  }

  getCountItemsByStatus(status: ConfigsStatus): number {
    return this.configStatusTotals ? this.configStatusTotals[status] : 0;
  }
}
