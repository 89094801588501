














































































import { Component, Prop, Vue } from "vue-property-decorator";

import TestResultIcon from "@/ab-tests/components/TestResultIcon.vue";

import AbTestConfigurationModel from "@/ab-tests/models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";

@Component({ components: { TestResultIcon } })
export default class AbTestCard extends Vue {
  @Prop() abTest!: AbTestConfigurationModel;

  readonly ConfigsStatus = ConfigsStatus;

  get menuItems(): Array<Record<string, any>> {
    return [
      ...(this.abTest.editable && this.abTest.hasEditAccess
        ? [
            {
              id: 1,
              route: {
                name: "abTest_edit_configs",
                params: {
                  configId: this.abTest.id,
                },
              },
              label: this.$lang("abTestConfig.actions.editConfigs"),
            },
          ]
        : []),
      ...(this.abTest.isStatusSwitchable
        ? [
            {
              id: 2,
              function: this.toggleStatus,
              label: this.abTest.isDisabled
                ? this.$lang("abTestConfig.actions.activate")
                : this.$lang("abTestConfig.actions.disable"),
            },
          ]
        : []),
      ...(this.abTest.deletable && this.abTest.hasDeleteAccess
        ? [
            {
              id: 3,
              function: this.handleDeleteAbTest,
              label: this.$lang("abTestConfig.actions.delete"),
            },
          ]
        : []),
    ];
  }

  toggleStatus() {
    this.$store.dispatch(
      this.abTest.isDisabled ? "enableAbTest" : "disableAbTest",
      {
        abTest: this.abTest,
      }
    );
  }

  handleDeleteAbTest() {
    this.$store.dispatch("deleteAbTest", this.abTest);
  }
}
