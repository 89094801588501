


















import { Component, Prop, Vue } from "vue-property-decorator";

import { AbTestResultType } from "@/ab-tests/models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";

@Component
export default class TestResultIcon extends Vue {
  @Prop() status!: ConfigsStatus;
  @Prop() resultType?: AbTestResultType;

  get color(): string {
    if (
      this.status !== ConfigsStatus.STATISTICS_RECALCULATED ||
      !this.resultType
    ) {
      return "blue-grey";
    }

    return {
      POSITIVE: "green",
      NEGATIVE: "red",
      NEUTRAL: "grey",
      UNCERTAIN: "blue",
      INCONSISTENT_POSITIVE: "blue",
    }[this.resultType];
  }

  get icon(): string {
    if (
      this.status !== ConfigsStatus.STATISTICS_RECALCULATED ||
      !this.resultType
    ) {
      return "mdi-timer-sand";
    }

    return {
      POSITIVE: "mdi-arrow-up-bold-circle-outline",
      NEGATIVE: "mdi-arrow-down-bold-circle-outline",
      NEUTRAL: "mdi-minus-circle-outline",
      UNCERTAIN: "mdi-help-circle-outline",
      INCONSISTENT_POSITIVE: "mdi-help-circle-outline",
    }[this.resultType];
  }
}
