var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-grow-1 flex-column fill-height",attrs:{"to":{
    name: 'abTest_view',
    params: {
      configId: _vm.abTest.id,
    },
  },"hover":"","outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.abTest.name)+" ")]),(_vm.menuItems.length)?_c('v-col',{attrs:{"cols":2}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1854471367)},[_c('v-list',_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.id,attrs:{"link":"","to":item.route || null},on:{"click":function($event){item.function() || null}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"d-flex flex-grow-1"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"min-width":"0"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.creator"))+":")]),_vm._v(" "+_vm._s(_vm.abTest.creatorName)+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.type"))+":")]),_vm._v(" "+_vm._s(_vm.$lang(("abTestType." + ((_vm.abTest.abTestType || "").toLowerCase()))))+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.affectedAuditory"))+":")]),_vm._v(" "+_vm._s(_vm.abTest.affectedAuditory)+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.testGroups"))+":")]),_vm._v(" "+_vm._s(_vm.abTest.segmentStatistics.length - 1)+" ")]),_c('div',{staticClass:"text-truncate"},[_c('b',[_vm._v(_vm._s(_vm.$lang("commonConfig.hypothesis"))+":")]),_vm._v(" "+_vm._s(_vm.abTest.hypothesis)+" ")])]),_c('test-result-icon',{attrs:{"status":_vm.abTest.status,"result-type":_vm.abTest.resultType}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }