












import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection } from "@/shared/models";

@Component
export default class TestInfo extends mixins(AppSectionAccessMixin) {
  readonly URL = "https://x-flow-ltd.atlassian.net/wiki/spaces/BIR/pages";

  get url(): string {
    let page = "";

    switch (this.appSection) {
      case AppSection.AB_TESTS:
        page = "/182943747/AB+Tests+FAQ";
        break;
      case AppSection.TARGETED_CONFIGS:
        page = "/183631873/Targeted+Configs+FAQ";
        break;
      case AppSection.EXTERNAL_TESTS:
        page = "";
        break;
      case AppSection.DEFAULT_CONFIG:
        page = "/603553793/Default+Config+FAQ";
        break;

      default:
        page = "";
    }

    return `${this.URL}${page}`;
  }
}
